import React, {Component} from 'react';

const Content = props => {
  return (
    <div className="content">

      <p>I am the technical team manager in for one business unit in my company. I help my team members by coaching them on a technical level, assuming some responsibilities as SCRUM master and helping them run the day-to-day operations. I do some DevOps work, in function of supporting the team to release faster and better. I have a strong focus on delivering real quality to the customers.</p>

      <p>I used to be a Business Analyst with a strong focus on practical insights.</p>

      <p>I am still a web developer and web designer focused on bringing applications that remove friction and empower users. The whole process from design to deployment is an organic growth process, in which I keep learning new interactions, techniques, tech and people.</p>

      <p>There are three key technologies upon which I am building or have built my skillset. Symfony, React and Docker.</p>

      <p>Working with Symfony allows me to create reliable and testable microservices that will scale up, be manageable and will push back on technical debt. React allows me to create lean and reactive front-end user interfaces that people like to use. Docker will then allow me to all put them out to the world.</p>

      <p>I feel a great sense of pride in products and services I have the privilege of working on. To me code quality is essential to being able to change your product fast, and I will always aim to keep the bar high.</p>

      <hr/>

      <p>
        I'm available for consultancy via <a href="https://craftzing.com">Craftzing</a>, before that I was Team Lead for <a href="https://teamleader.eu">Teamleader CRM</a>, <b>Technical team manager</b> at <a href="http://bazookas.be">Bazookas</a>. I used to work for <a href="https://moovly.com">Moovly</a> which
        is a video editing SaaS application, before that I was a consultant for <a href="https://phpro.be">PHPro</a>.
        I'm also available for work for hire at my <a href="https://codingculture.be">Coding Culture</a>.
      </p>
    </div>
  )
};

export default Content;
