import * as React from 'react';

import './Blogposts.scss';

class Blogposts extends React.Component
{
  componentDidMount() {
    const fmSettings = {
      feedURL: 'https://data.feedmirror.com/-LrFtROn4D-pK35HP_Fi.json',
      integration: 'medium-embed',
      linkOutText: 'Read more',
      linkToMediumProfileText: 'Subscribe on Medium',
      postsCount: 3,
      element: 'fm-medium-embed'
    };

    window.feedmirror.initialize(fmSettings);
  }

  render()
  {
    return (
      <div className="content">
        <h3>Ramblings: </h3>

        <div id="fm-medium-embed" />
      </div>
    )
  }
}

export default Blogposts;