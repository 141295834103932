import * as React from 'react';
import ReactDOM from 'react-dom';
import Masthead from './components/Masthead/Masthead.jsx';
import Content from './components/Content/Content.jsx';
import Buttons from './components/Buttons/Buttons.jsx';
import Blogposts from "./components/Blogposts/Blogposts.jsx";

import './styles/main.scss';

const App = (props) => {
  return (
    <div>
      <div className="bumper" />
      <Masthead />
      <Buttons />
      <Content />
      <Blogposts />
    </div>
  )
};

ReactDOM.render(<App />, document.getElementById('app'));
