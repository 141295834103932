import React, { Component } from 'react';

const Masthead = (props) =>
{
  return (
    <header className="masthead">
      <div className="masthead masthead__content">
        <img src="https://www.gravatar.com/avatar/5dcb0e20c71a98978bb66d7cdda21447?s=250" />

        <h2>
          Niels Vermaut
        </h2>
      </div>
    </header>
  )
};

export default Masthead;