import React, { Component } from 'react';

const buttons = [
  {"key": "Github", "link": "https://github.com/nielsvermaut"},
  {"key": "Twitter", "link": "https://twitter.com/nielsvermaut"},
  {"key": "LinkedIn", "link": "https://www.linkedin.com/in/niels-vermaut-5869715b"},
  {"key": "Medium", "link": "https://medium.com/@nielsvermaut"},
];

const Buttons = (props) =>
{
  return (
    <div className="buttons">
      {buttons.map(button => {
        return (<a className="buttons__button" href={button.link} key={button.key}>{button.key}</a>);
      })}
    </div>
  );
};

export default Buttons;